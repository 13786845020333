<template>
  <div>
    <v-card class>
      <loading :active.sync="loading" />
      <v-flex pb-3>
        <span class="color-orange breakword">GPS Devices</span>
      </v-flex>
      <v-card-title class="bg-clr">
        <v-layout justify-space-around wrap>
          <v-avatar tile color="#ffa500" class="total avatar">
            <span class="white--text breakword">
              Total device count
              <span class="count">{{ totalDeviceCount || "0" }}</span>
            </span>
          </v-avatar>

          <v-avatar tile color="#ffa500" class="total avatar">
            <span class="white--text breakword">
              Active device count
              <span class="count">{{ activeDeviceCount || "0" }}</span>
            </span>
          </v-avatar>
          <v-avatar tile color="#ffa500" class="total avatar">
            <span class="white--text breakword">
              Idle device count
              <span class="count">{{ idleDeviceCount || "0" }}</span>
            </span>
          </v-avatar>
        </v-layout>

        <AddImei @add-Imei="componentKey += 1" />
      </v-card-title>

      <v-container fluid>
        <v-layout row pt-3 class="">
          <v-flex xs2 md2>
            <v-select
              v-model="searchTypes"
              :items="searchItems"
              flat
              label="Search Type"
              item-text="status"
              single-line
              class="pt-0"
              item-value="abbr"
              persistent-hint
              @change="searchByType()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md3 pr-3>
            <v-text-field
              height="55px"
              class="gaps"
              label="Search here.."
              outline
              single-line
              :disabled="!searchTypes"
              v-model="search"
              @keyup.enter="searchText"
              @click:append="searchText"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex
            xs3
            md3
            pl-4
            style="padding-top: 18px"
            class="wrapper"
            id="GPSDate"
          >
            <ejs-daterangepicker
              id="approvalPicker"
              :placeholder="waterMark"
              v-model="dateRange2"
            ></ejs-daterangepicker>
          </v-flex>
          <v-flex xs2 md2 pl-4>
            <v-select
              v-model="statusType"
              :items="statusTypeItems"
              label="Select Status Type"
              item-text="status"
              item-value="abbr"
              @change="searchByStatusType()"
              persistent-hint
              return-object
            ></v-select>
          </v-flex>
          <v-flex xs3 md3 class="reset-btn">
            <v-card-actions style="justify-content: flex-end">
              <v-btn
                flat
                class="reset-btn-color"
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-text class="nospace">
        <v-data-table
          id="ImeiTable"
          hide-actions
          :headers="headers"
          :items="items"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.imeiNumber || "-" }}</td>
            <td>{{ checkWorkingCountry(props.item.countrySortName) }}</td>
            <td v-if="props.item.assignedStatus">Fixed</td>
            <td v-else>In-hand</td>
            <td v-if="props.item.assignmentData">
              {{ props.item.assignmentData.jobId }}
            </td>
            <td v-else>-</td>
            <td v-if="props.item.assignmentData">
              {{ props.item.assignmentData.assignmentId }}
            </td>
            <td v-else>-</td>
            <td v-if="props.item.assignmentData">
              {{ getTime3(props.item.assignmentData.pickupDate) }}
            </td>
            <td v-else>-</td>
            <td v-if="props.item.driverData">
              {{ props.item.driverData.name }}<br />
              <img src="../../assets/TruckIcon.svg" height="15px" /> TRK-{{
                props.item.driverData.truckNumber
              }}
            </td>
            <td v-else>-</td>

            <td v-if="props.item.createdAt">
              {{ getTime3(props.item.createdAt) }}
            </td>
            <td v-else>-</td>
            <td>
              <GpsCollectionPopup
                v-permissions="'collect-gps'"
                v-if="
                  props.item.assignedStatus &&
                    props.item.assignmentData &&
                    props.item.assignmentData.assetId &&
                    (props.item.assignmentData.assignmentStatus == 6 ||
                      props.item.assignmentData.assignmentStatus == 3 ||
                      props.item.assignmentData.assignmentStatus == 4)
                "
                :assignmentData="props.item.assignmentData"
                :type="'imeiSection'"
                @refresh-list="refreshList()"
              />
              <button
                v-if="!props.item.assignedStatus"
                target="_blank"
                @click="getDeviceId(props.item._id)"
                type="button"
                :disabled='loading'
                class="red-btn mt-10px"
                v-permissions="'delete-gps-device'"
              >
                Delete
              </button>
            </td>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
        <pagination
          :totalPages="totalPages"
          :pages="this.pages"
          @pageNo="page"
          :url="imeiList"
          :callback="callback"
          :componentKey="componentKey"
          :action="`gps`"
          :payload="payload"
          :type="1"
          v-on:pagination-load="checkLoading"
        />
      </v-card-text>
      <v-dialog
        v-model="deleteDialog"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title style="color: black">{{
              deleteTitle
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              :disabled="loading"
              @click.prevent="deleteDialog = false"
            >
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-show="!!deleteMessage" class="pa-4">
            <v-layout pt-2>
              <span style="color: lightgrey; font-size: 15px">{{
                deleteMessage
              }}</span>
            </v-layout>
          </v-card-text>

          <v-card-actions style="padding-bottom: 30px">
            <v-spacer></v-spacer>
            <v-btn :disabled="loading" flat @click="deleteDialog = false"
              >CANCEL</v-btn
            >

            <button
              target="_blank"
              @click="check()"
              type="button"
              :disabled="loading"
              class="orange-btn"
            >
              Yes, Delete
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { imeiSectionApiUrl } from "@/constants/api-urls.js";
import { imeiList } from "@/constants/datatable-headers.js";
import pagination from "@/components/Pagination/pagination";
import GpsCollectionPopup from "@/components/Jobs/JobDetails/GpsCollectionPopup";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
Vue.use(DateRangePickerPlugin);
import { EventBus } from "../../event-bus.js";
import Loading from "vue-loading-overlay";
import DateRangePicker from "vue2-daterange-picker";
let momentTz = require("moment-timezone");
import AddImei from "./AddImei";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import {
  StorageKeys,
  JobListTypes,
  CancellationType,
} from "../../constants/constants";
import SelectCountry from "../Common/SelectCountry";
import { async } from "q";
export default {
  beforeCreate() {},
  created() {
    this.$eventBus.$on("imei-section-collect-gps", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      this.payload = {
        workingCountry: this.workingCountry,
      };
    });

    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });

      this.workingCountry = JSON.stringify(workingCountry);
      this.payload = {
        workingCountry: this.workingCountry,
      };
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.loading = true;
      this.payload.workingCountry = this.workingCountry;
    });

    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });
  },

  mounted() {},

  components: {
    pagination,
    GpsCollectionPopup,
    SelectCountry,
    AddImei,
    Loading,
    DateRangePicker,
  },
  data() {
    return {
      imeiList: imeiSectionApiUrl.imeiList,
      searchItems: [
        { status: "IMEI Number", abbr: "imeiNumber" },
        { status: "Assignment Id", abbr: "assignmentId" },
        { status: "Job Id", abbr: "jobId" },
        { status: "Truck Number", abbr: "truckNumber" },
        { status: "Driver name", abbr: "driverName" },
      ],
      searchTypes: "",
      waterMark: "Select a Range",
      dateRange2: null,
      componentKey: 0,
      picker: {},
      statusTypeItems: [
        { status: "All", abbr: "All" },
        { status: "Fixed", abbr: "Fixed" },
        { status: "In-hand", abbr: "InHand" },
      ],
      statusType: "All",
      startDate: "",
      endDate: "",
      dateRange: { startDate: "", endDate: "" },
      selectCountryDialog: false,
      deviceId: null,
      workingCountry: null,
      pagination: {},
      options: {
        color: "rgb(245, 245, 245)",
        width: 500,
        zIndex: 200,
      },
      deleteDialog: false,
      deleteTitle: "Delete IMEI Device",
      deleteMessage:
        "*Deleting an IMEI will permanently remove it from library",
      operation: "",
      operationName: "",
      FilterDateBool: false,
      rules: {
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },

      x: {
        error: null,
      },
      currentEnquiry: null,
      currentIndex: null,
      totalDeviceCount: "",
      activeDeviceCount: "",
      idleDeviceCount: "",
      payload: {},
      search: "",
      pages: 1,
      totalPages: 0,
      pages: 1,
      isNoSearchResult: false,
      loading: false,
      headers: imeiList,
      items: [],
    };
  },
  props: {},
  watch: {
    pagination(val) {
      if (val["sortBy"] === "ImeiDevice") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "imeiNumber";
          this.payload["sortType"] = -1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "imeiNumber";
          this.payload["sortType"] = 1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      } else if (val["sortBy"] === "jobId") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "jobId";
          this.payload["sortType"] = -1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "jobId";
          this.payload["sortType"] = 1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      } else if (val["sortBy"] === "assignId") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "assignId";
          this.payload["sortType"] = -1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "assignId";
          this.payload["sortType"] = 1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      } else if (val["sortBy"] === "startDate") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "assignDate";
          this.payload["sortType"] = -1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "assignDate";
          this.payload["sortType"] = 1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      } else if (val["sortBy"] === "drivername") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "driverName";
          this.payload["sortType"] = -1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "driverName";
          this.payload["sortType"] = 1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      } else if (val["sortBy"] === "updatedon") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "addedOn";
          this.payload["sortType"] = -1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "addedOn";
          this.payload["sortType"] = 1;
          this.payload.isSearch = "true";
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      }

      if (!val["sortBy"]) {
        delete this.payload["sortType"];
        delete this.payload["sortBy"];
        delete this.payload["isSearch"];
        if (val["descending"] == null) {
          this.componentKey = this.componentKey + 1;
        }
      }
      deep: true;
    },

    dateRange2(val) {
      if (val) {
        if (val.length) {
          if (val[0] && val[1]) {
            localStorage.setItem("gpstartdateFilter", val[0]);
            localStorage.setItem("gpsenddateFilter", val[1]);
            this.startDate = moment(val[0]).format("YYYY-MM-DD");
            this.endDate = moment(val[1]).format("YYYY-MM-DD");
          }
          this.$set(this.payload, "startDate", this.startDate);

          this.payload.isSearch = "true";

          this.payload.workingCountry = this.workingCountry;
          this.payload.endDate = this.endDate;
        }
      }
    },
  },
  methods: {
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    page(event) {
      this.pages = event;
    },

    refreshList() {
      this.componentKey = this.componentKey + 1;
    },
    checkWorkingCountry(country) {
      var workingCountryArray = [];
      JSON.parse(this.workingCountry).forEach((key, index) => {
        if (country && country.includes(key)) {
          workingCountryArray.push(key);
        }
      });
      return workingCountryArray.toString();
    },

    async check() {
      this.loading=true;
      this.operationName = "delete-gps-device";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        
        this.deleteDevice();
      } else {        
      this.loading=false;
        return;
      }
    },
    paginationPayload(event) {
      this.loading = event;
      this.payload = event;
      localStorage.removeItem("current-customer-page-status");
    },

    getTime3(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    callback(res) {
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);

      this.pagination.rowsPerPage = 15;
      this.idleDeviceCount = res.freeAsset;
      this.totalDeviceCount = res.totalData;
      this.activeDeviceCount = res.busyAsset;
      this.loading = false;
    },
    searchByType() {
      this.search = "";
      this.searchText();
    },
    searchText() {
      if (localStorage.getItem("searchImeiText") == this.search) {
        return;
      }
      if (this.search.trim() != "") {
        localStorage.setItem("searchImeiText", this.search);
        localStorage.setItem("searchTypeImei", this.searchTypes.abbr);
        this.loading = true;
        this.payload.searchType = this.searchTypes.abbr;

        this.payload.workingCountry = this.workingCountry;
        this.payload.isSearch = "true";
        this.$set(this.payload, "newSearchText", this.search);
      } else {
        if (this.searchTypes) {
          this.payload.searchType = this.searchTypes.abbr;
        }
        if (this.dateRange.startDate && this.dateRange.startDate) {
          this.loading = false;
          this.startDate = moment(this.dateRange.startDate).format(
            "YYYY-MM-DD"
          );
          this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
          this.payload = {
            isSearch: "true",
            workingCountry: this.workingCountry,
            startDate: this.startDate,
            endDate: this.endDate,
          };
        } else if (this.statusType) {
          if (this.statusType.abbr == "All") {
            this.payload = {
              workingCountry: this.workingCountry,
              isSearch: "true",
            };
          } else {
            this.payload = {
              isSearch: "true",
              workingCountry: this.workingCountry,
              deviceStatus: this.statusType.abbr,
            };
          }
        } else if (
          this.statusType &&
          this.dateRange.startDate &&
          this.dateRange.startDate
        ) {
          this.loading = false;
          if (this.statusType.abbr == "All") {
            this.payload = {
              isSearch: "true",
              workingCountry: this.workingCountry,
              startDate: this.startDate,
              endDate: this.endDate,
            };
          } else {
            this.payload = {
              isSearch: "true",
              workingCountry: this.workingCountry,
              startDate: this.startDate,
              endDate: this.endDate,
              deviceStatus: this.statusType.abbr,
            };
          }
        } else {
          this.loading = false;

          this.payload.workingCountry = this.workingCountry;
          this.$set(this.payload, "searchType", this.searchTypes.abbr);
        }
      }
    },

    searchByStatusType() {
      if (this.statusType.abbr !== "All") {
        this.loading = true;

        this.payload.isSearch = "true";
        this.payload.workingCountry = this.workingCountry;
        this.$set(this.payload, "deviceStatus", this.statusType.abbr);
      } else {
        localStorage.setItem("gpsStatusType", this.statusType.abbr);
        if (this.search) {
          this.payload = {
            isSearch: "true",
            workingCountry: this.workingCountry,
            newSearchText: this.search,
            searchType: this.searchTypes.abbr,
          };
        } else if (this.dateRange.startDate && this.dateRange.endDate) {
          this.startDate = moment(this.dateRange.startDate).format(
            "YYYY-MM-DD"
          );
          this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
          this.payload = {
            isSearch: "true",
            workingCountry: this.workingCountry,
            startDate: this.startDate,
            endDate: this.endDate,
          };
        } else if (
          this.search &&
          this.dateRange.startDate &&
          this.dateRange.endDate
        ) {
          this.startDate = moment(this.dateRange.startDate).format(
            "YYYY-MM-DD"
          );
          this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
          this.payload = {
            isSearch: "true",
            workingCountry: this.workingCountry,
            startDate: this.startDate,
            endDate: this.endDate,
            newSearchText: this.search,
            searchType: this.searchTypes.abbr,
          };
        } else {
          this.payload = {
            workingCountry: this.workingCountry,
          };
        }
      }
    },
    reset() {
      this.search = "";
      localStorage.removeItem("searchImeiText");
      localStorage.removeItem("gpstartdateFilter");
      localStorage.removeItem("gpsenddateFilter");
      localStorage.removeItem("searchTypeImei");
      localStorage.removeItem("gpsStatusType");
      this.statusType = "All";
      this.searchTypes = "";
      this.dateRange.startDate = null;
      this.dateRange2 = null;
      this.dateRange.endDate = null;
      this.payload = {
        workingCountry: this.workingCountry,
      };
    },
    getDeviceId(number) {
      this.deviceId = number;
      this.deleteDialog = true;
    },
    deleteDevice() {
      this.loading = true;
      this.processing = true;
      let Url = `/dmsAdmin/deleteAsset?assetId=${this.deviceId}`;
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.put(this.constants.apiUrl + Url, {}, config).then(
        (response) => {
          this.loading = false;
          this.deleteDialog = false;
          this.componentKey += 1;
        },
        (error) => {
          this.x.success = false;
          this.loading = false;
          this.x.error = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style lang="scss">
#ImeiTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#ImeiTable .theme--light.v-datatable thead th.column.sortable.active .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
div#ej2-datetimepicker_0_popup {
  left: 905.2px !important;
}
#GPSDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#GPSDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 100px !important;
  width: 100%;
  text-align: right;
}
#GPSDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#GPSDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
  height: 100%;
}
#GPSDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
</style>

<style scoped>
.mt-10px {
  margin-top: 7px;
}
.orange-btn {
  text-decoration: none;
  color: #ffffff;
  border-radius: 4px;
  background-color: darkorange;
  border: 2px solid darkorange;
  padding: 5px 15px;
}
.red-btn {
  text-decoration: none;
  color: #ffffff;
  border-radius: 4px;
  background-color: lightcoral;
  border: 2px solid red;
  padding: 5px 15px;
}

.theme--light.menuable__content__active {
  min-width: 290px !important;
}
.date-picker {
  min-width: 100%;
}
.color-orange {
  color: darkorange !important;
  font-size: 25px;
  padding-top: 15px;
}
.minHeight {
  min-height: 300px;
}
.colorchange {
  background-color: #fae3e3 !important;
}
.colorchangeyellow {
  background-color: #ffffe0 !important;
}
.spacerclass {
  max-height: 550px !important;
}
.reset {
  margin-top: 10px;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
.color-orange {
  color: darkorange !important;
  font-size: 25px;
  padding-top: 15px;
}
</style>
